import { Injectable, signal } from "@angular/core";
import { ISideMenuCommand, SideMenuCmdsService } from "@webapp-wkspace/shell";
import { PRODUCTION_ROUTER_TOKENS } from "../feature-production/feature.router-tokens";
import { ENG_ROUTER_TOKENS } from "../feature-engineering/feature.router-tokens";

@Injectable({
    providedIn: 'root'
})
export class Lt78SideMenuCmdsService implements SideMenuCmdsService {
    cmds = signal<ISideMenuCommand[]>([
        {
            icon: 'precision_manufacturing',
            label: 'Produzione',
            route: PRODUCTION_ROUTER_TOKENS.BASE_URL,
        },
        {
            icon: 'settings',
            label: 'Configurazione',
            route: ENG_ROUTER_TOKENS.BASE_URL,
        },        
    ]);
}