import { DateTime } from "luxon";
import { IUserActivityInfo } from "./user-activity-info";
import { IPermission, IRole } from "./user-permissions";


export interface IUser extends IUserActivityInfo {
    id: number;
    email: string;
    email_verified_at: string | null;   
    two_factor: boolean;
    two_factor_confirmed_at: string | null;  
    
    permissions?: IPermission[];
    roles?: IRole[];
    info?: IUserInfo;
}
export const EMPTY_USER : IUser = {
    id: 0,
    email: '',
    email_verified_at: null,
    two_factor: false,
    two_factor_confirmed_at: null,
};

// Uno user di tipo X può avere più ruoli, ognuno con i suoi permessi.
export interface IUserType {
    id: number;
    name: string;
    description: string;
}
export const EMPTY_USER_TYPE: IUserType = {   
    id: 0,  name: 'unknown', description: 'unknown'
};

export interface IUserInfo extends IUserActivityInfo {
    id: number;
    name: string;
    surname: string;

    user?: IUser;
    user_type?: IUserType;
}

export const EMPTY_USER_INFO : IUserInfo = {
    id: 0,
    name: '',
    surname: '',
};

export interface IUserLite extends IUserActivityInfo {
    id: number;
    email: string;
    name: string;
    surname: string;
    user_type_id: number;
}
export const EMPTY_USER_LITE : IUserLite = {
    id: 0,
    email: '',
    name: '',
    surname: '',
    user_type_id: EMPTY_USER_TYPE.id,
};