import { Routes } from '@angular/router';
import { auth_routes, loggedInRouteGuard } from '@webapp-wkspace/auth';
import { Error404Component } from '@webapp-wkspace/common';


export const routes: Routes = [
    ...auth_routes,
    { 
        path: '',
        canActivateChild: [loggedInRouteGuard()],
        loadChildren: () => import('./main/main.routes').then(m => m.MAIN_ROUTES),
    },
    {
        path: '**',
        component: Error404Component
    }    
];

