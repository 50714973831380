import { Injectable, computed, inject, signal } from '@angular/core';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { ILoginResponse, IRegistration, IResetPassword, IUpdatePassword } from '../auth-model';
import { CookieService } from 'ngx-cookie-service';
import { HttpAuthService } from './http-auth.service';
import { DateTime } from 'luxon';
import { EMPTY_USER, IUser, LoggerService } from '@webapp-wkspace/common';

@Injectable({
    providedIn: 'root'
})
export class LibAuthService {
    private readonly LOGGED_USER_COOKIE_NAME = 'is_user_logged_in';
    private readonly me = 'LibAuthService';
    redirectUrl: string | undefined = undefined;

    private readonly httpAuthService = inject(HttpAuthService);
    private readonly cookieService = inject(CookieService);
    private readonly logger = inject(LoggerService);
    
    // da capire se loggedUser deve essere signal ... 
    loggedUser = signal<IUser>(EMPTY_USER);
    two_factor_login: boolean|undefined = undefined; // se twofa enabled, check anche se login2fa andato a buon fine.

    isLoggedIn = computed((): boolean => this.loggedUser().id > 0 );
    isEmailVerified = computed(() => this.isLoggedIn() && this.loggedUser().email_verified_at !== null );
    two_fa_enabled_and_confirmed = computed(() => 
        this.isLoggedIn() &&
        this.loggedUser().two_factor &&
        (!!this.loggedUser().two_factor_confirmed_at));
    private user_permissions = computed(() => {
        const permissions = this.loggedUser().permissions ?? [];
        // console.log('computed permissions', permissions);
        const role_permissions = (this.loggedUser().roles ?? [])
            .flatMap(role => role.permissions ?? []);
        // console.log('computed role_permissions', role_permissions);
        const all_permission = [
            ...permissions,
            ...role_permissions,
        ].filter((value, idx, self) => idx == self.indexOf(value)); // distinct
        // console.log('computed user_permissions', all_permission);
        return all_permission;
    })

    // [[a,b],[c]] ::= (a|b) & (c)
    userCan(permissions: (string[])[]): boolean {
        return permissions.every(permission_group => permission_group
            .some(permission => this.user_permissions().some(x => x.name === permission) ));
    }

    setLoggedUser(user_data: IUser): void {
        this.loggedUser.set(user_data);
        this.cookieService.set(this.LOGGED_USER_COOKIE_NAME, 'true', { expires: 86400, sameSite: 'Lax' });
    }
    clearLoggedUser(): void {
        this.loggedUser.set(EMPTY_USER);
        this.cookieService.delete(this.LOGGED_USER_COOKIE_NAME);
    }

    // ----------------------------------------------------------------------------------------------------------------

    test() {
        this.httpAuthService.test();
    }

    login(email: string, password: string): Observable<ILoginResponse> {
        return this.httpAuthService.login(email, password);
    }
    
    logout() {
        return this.httpAuthService.logout().pipe(            
            tap(() => this.clearLoggedUser()),
        );
    }

    register(data: IRegistration): Observable<IUser> {
        return this.httpAuthService.register(data).pipe(            
            tap((srvUser: IUser) => this.setLoggedUser(srvUser)),
        );
    }

    getLoggedUser(): Observable<IUser> {
        return this.httpAuthService.getLoggedUser().pipe(            
            tap((srvUser: IUser) => this.setLoggedUser(srvUser)),
        );
    }

    forgotPassword(email: string) {
        return this.httpAuthService.forgotPassword(email);
    }

    resetPassword(data: IResetPassword) {
        return this.httpAuthService.resetPassword(data);
    }

    sendNewActivationLink() {
        return this.httpAuthService.sendNewActivationLink();
    }

    updateEmail(email: string) {
        return this.httpAuthService.updateEmail(email).pipe(            
            tap(() => this.loggedUser.update((loggedUser: IUser) => {
                return {
                    ...loggedUser,
                    email: loggedUser.email,
                    email_verified_at: null,
                } as IUser;
            })),
        );
    }

    updatePassword(pwd: IUpdatePassword) {
        return this.httpAuthService.updatePassword(pwd);
    }

    /*
        While building your application, you may occasionally have actions that should 
        require the user to confirm their password before the action is performed. 
        Typically, these routes are protected by Laravel's built-in password.confirm middleware.
    */
    confirmPassword(password: string) {
        return this.httpAuthService.confirmPassword(password);
    }

    getConfirmPasswordStatus() {
        return this.httpAuthService.getConfirmPasswordStatus().pipe(
            map( (pwdStatus) => pwdStatus.confirmed)
        );
    }

    enable2FA() {
        return this.httpAuthService.enable2FA().pipe(            
            tap(() => this.loggedUser.update((loggedUser: IUser) => {
                return {
                    ...loggedUser,
                    two_factor: true,
                    two_factor_confirmed_at: null,
                } as IUser;
            })),
        );
    }

    disable2FA() {
        return this.httpAuthService.disable2FA().pipe(            
            tap(() => this.loggedUser.update((loggedUser: IUser) => {
                return {
                    ...loggedUser,
                    two_factor: false,
                    two_factor_confirmed_at: null,
                } as IUser;
            })),
        );
    }

    getQrCode(): Observable<string> {
        return this.httpAuthService.getQrCode();
    }

    getRecoveryCodes(): Observable<string[]> {
        return this.httpAuthService.getRecoveryCodes();
    }

    confirmedTwoFactorAuthentication(authentication_code: string) {
        return this.httpAuthService.confirmedTwoFactorAuthentication(authentication_code).pipe(            
            tap(() => {
                this.two_factor_login = true;
                this.loggedUser.update((loggedUser: IUser) => {
                    return {
                        ...loggedUser,
                        two_factor_confirmed_at: DateTime.now().toLocaleString(),
                    } as IUser;
                });
            }),
        );
    }

    twoFactorChallenge(code: string, isVerificationCode: boolean) {
        return this.httpAuthService.twoFactorChallenge(code, isVerificationCode).pipe(
            tap(() => this.two_factor_login = true ),   // 204 no content
            catchError((err) => {                       // 422 Unprocessable Content
                this.two_factor_login = false;
                return throwError(() => err);
            }),
        );        
    }
}
