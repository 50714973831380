import { InjectionToken } from '@angular/core';

// It's an abstract class on purpose, as interfaces cannot be used as tokens for DI.
export abstract class AuthConfig {
    abstract srvUrl: string;
    abstract appVersion: string|null;
    abstract logoPath: string;
    abstract company: string;
    abstract user_can_register: boolean;
}

export const AUTH_CONFIG = new InjectionToken<AuthConfig>('auth.config');