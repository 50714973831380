import { EnvironmentProviders, makeEnvironmentProviders } from "@angular/core";
import { AUTH_CONFIG, AuthConfig } from "./auth-config";

export function provideAuthConfig(config: AuthConfig): EnvironmentProviders {

    return makeEnvironmentProviders([
        {
            provide: AUTH_CONFIG,
            useValue: config,
        },
    ]);
}