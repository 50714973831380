import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { UnAuthenticatedInterceptor, XsrfInterceptor, provideAuthConfig } from '@webapp-wkspace/auth';
import { SideMenuCmdsService } from '@webapp-wkspace/shell';

// By default, Angular only contains locale data for en-US. If you set the value of LOCALE_ID
// to another locale, you must import locale data for that new locale.
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { environment } from '../environments/environment';
import { provideHttpClient, withInterceptors, withNoXsrfProtection, withXsrfConfiguration } from '@angular/common/http';
import { Lt78SideMenuCmdsService } from './SideMenuCmds/lt78-side-menu-cmds.service';
import { ContentTypeInterceptor } from '@webapp-wkspace/common';
registerLocaleData(localeIt, 'it-IT');

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes), 
        provideAnimationsAsync(),        
        /*
        provideHeaderConfig({
            gradient_start: ,
            gradient_end
        }),
        */
        { provide: SideMenuCmdsService, useClass: Lt78SideMenuCmdsService },
        provideAuthConfig({
            srvUrl: environment.srvUrl,
            appVersion: '0.0.1',
            logoPath: 'assets/images/logo.png',
            company: 'Latterie 1978',
            user_can_register: false,
        }),
        provideHttpClient(
            
            withNoXsrfProtection(),
            
            withInterceptors([
                UnAuthenticatedInterceptor,
                ContentTypeInterceptor,
                XsrfInterceptor,
            ]),
        ),
        { provide: LOCALE_ID, useValue: 'it-IT' },
    ],
};
